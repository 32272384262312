<template>
	<div>
		<div class="row mb-3">
			<div class="col-md-3">
				<label>Branch</label>
				<input v-model="branchData" type="text" class="form-control form-control-sm" placeholder="Branch" disabled />
			</div>
			<div class="col-md-3">
				<label>Customer</label>
				<multiselect id="customer" v-model="customerData" placeholder="Choose a Customer" :searchable="true"
					:allow-empty="true" :clearable="true" :loading="isLoading" :multiple="true" :close-on-select="false"
					:options="listCustomerByBranch" track-by="id" label="name" required>
				</multiselect>
			</div>
			<div class="col-md-3">
				<label>Jobfile Type</label>
				<select v-model="jobfileTypeData" v-can="'CRM_ADMIN_ENABLE'" class="form-control" name="jobfileType"
					data-live-search="true" placeholder="Jobfile Type">
					<option value="All" selected>All</option>
					<option value="1">Export</option>
					<option value="2">Import</option>
					<option value="3">Domestic</option>
				</select>
			</div>
			<div class="col-md-3">
				<label>Marketing</label>
				<multiselect id="marketing" v-model="marketingData" placeholder="Choose a Marketing" :searchable="true"
					:allow-empty="true" :clearable="true" :loading="isLoading" :multiple="true" :close-on-select="false"
					:options="listMarketingByPermissions" track-by="id" label="name_user" required>
				</multiselect>
			</div>
			<div class="col-md-3">
				<label>Range</label>
				<select v-model="rangeData" v-can="'CRM_ADMIN_ENABLE'" class="form-control" name="created_by"
					data-live-search="true" placeholder="Range" @change="fecthData">
					<option value="customData" selected>Custom</option>
					<option value="monthlyData">Monthly</option>
					<!-- <option value="yearlyData">Yearly</option> -->
				</select>
			</div>
			<div v-if="rangeData === 'customData'" class="col-md-3 justify-content-end">
				<label>Start Date</label>
				<!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
				<date-picker v-model="startDateData" :format="momentFormatStartDateData" type="date" lang="en" :name="'startDate'"
					:placeholder="'Start Date'">
				</date-picker>
			</div>
			<div v-if="rangeData === 'customData'" class="col-md-3 justify-content-end">
				<label>End Date</label>
				<!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
				<date-picker v-model="endDateData" :format="momentFormatEndDateData" type="date" lang="en" :name="'endDate'"
					:placeholder="'End Date'" @input="fecthData">
				</date-picker>
			</div>
			<div v-if="rangeData === 'monthlyData'" class="col-md-3 justify-content-end">
				<label>Month</label>
				<select v-model="monthData" v-can="'CRM_ADMIN_ENABLE'" class="form-control" name="created_by"
					data-live-search="true" placeholder="Range" @change="fecthData">
					<option value="01">January</option>
					<option value="02">February</option>
					<option value="03">March</option>
					<option value="04">April</option>
					<option value="05">May</option>
					<option value="06">June</option>
					<option value="07">July</option>
					<option value="08">August</option>
					<option value="09">September</option>
					<option value="10">October</option>
					<option value="11">November</option>
					<option value="12">December</option>
				</select>
			</div>
			<div v-if="rangeData === 'monthlyData' || rangeData === 'yearlyData'">
				<label>Year</label>
				<!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
				<date-picker v-model="yearData" type="year" :format="formatYearData" :name="'yearData'" :placeholder="'Year'"
					@input="fecthData">
				</date-picker>
			</div>
			<div class="col-md-3">
				<label>Status</label>
				<select v-model="statusData" v-can="'CRM_ADMIN_ENABLE'" class="form-control" name="created_by"
					data-live-search="true" placeholder="Status">
					<option value="All" selected>All</option>
					<option value="paid">Paid</option>
					<option value="outstanding">Outstanding</option>
					<option value="unpaid">Unpaid</option>
				</select>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6" style="align-content: center; display: flex"> </div>
			<div class="col-md-6" style="display: flex; justify-content: end">
				<div>
					<b-dropdown id="dropdown-1" right variant="success" class="m-md-2" no-caret>
						<template v-slot:button-content>
							Preview
							<i class="fa fa-caret-down ml-2"></i>
						</template>
						<b-dropdown-item @click="doFilterMultipleKeywords"><i class="fa fa-edit mr-2"></i>Filter
							Data</b-dropdown-item>
						<b-dropdown-item @click="exportData"><i class="fa fa-edit mr-2"></i>Export Excel</b-dropdown-item>
					</b-dropdown>
				</div>
			</div>
		</div>
		<!-- <h4 class="header-title">List Quotation</h4> -->
		<vue-element-loading :active="isLoadingTable" spinner="bar-fade-scale" color="#FF6700" />

		<!-- Table -->
		<div class="table-responsive">
			<vuetable ref="vuetable" :per-page="perPage" :fields="fields" :api-url="apiUrl" :api-mode="true"
				:http-options="httpOptions" :append-params="moreParams" pagination-path class="table table-centered table-striped"
				data-path="data" @vuetable:loading="loadingTable" @vuetable:load-success="loadTableSuccess"
				@vuetable:load-error="loadTableFailed" @vuetable:pagination-data="onPaginationData">
				<div slot="status" slot-scope="props">
					<span v-if="props.rowData.status === 'Paid'" class="px-3 py-1 bg-success text-white">Paid</span>
					<span v-else-if="props.rowData.status === 'Outstanding'"
						class="px-3 py-1 bg-warning text-white">Outstanding</span>
					<span v-else class="px-3 py-1 bg-danger text-white">Unpaid</span>
				</div>
			</vuetable>
		</div>
		<!-- end table -->

		<!-- pagination -->
		<div class="row">
			<div class="col-md-5">
				<div class="row no-gutters">
					<div class="col-lg-3">
						<div class="form-group float-lg-left">
							<select v-model="perPage" class="form-control form-control-sm" @change="onChangeTableLength">
								<option v-for="opt in lengthMenu" :key="opt" :value="opt">
									{{ opt }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-md-8">
						<vuetable-pagination-info ref="paginationInfo" class="
                justify-content-center justify-content-lg-start
                text-center text-lg-left
              ">
						</vuetable-pagination-info>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-7">
				<vuetable-pagination ref="pagination" :css="css.pagination" class="
            pagination pagination-rounded
            justify-content-center justify-content-lg-end
          " @vuetable-pagination:change-page="onChangePage">
				</vuetable-pagination>
			</div>
		</div>
		<!-- end pagination -->
	</div>
</template>
<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import { authMethods } from '@state/helpers'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker'
// import { log } from 'console'
// import { array } from 'yargs'

var url = '/report-ontime'

for (
	let i = 0;
	i < JSON.parse(localStorage.getItem('userPermissions')).length;
	i++
) {
	if (
		JSON.parse(localStorage.getItem('userPermissions'))[i] ===
		'CRM_ADMIN_ENABLE'
	) {
		url = '/report-ontime'
	}
}
console.log(url, 'url')

export default {
	components: {
		Vuetable,
		VuetablePagination,
		VuetablePaginationInfo,
		DatePicker,
		Multiselect,
	},
	props: {
		showActions: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			moreParams: {},
			filterText: '',
			deleteData: {},
			isLoadingTable: false,
			httpOptions: {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				},
			},
			apiUrl: this.$store.state.rootApi + url,
			urlPdf: '#',
			items: [
				{
					text: 'Master',
					href: '/',
				},
				{
					text: 'Quotation',
					href: '/quotation',
				},
				{
					text: 'List',
					active: true,
				},
			],
			lengthMenu: [10, 25, 50, 100],
			perPage: 10,
			fields: [
				{
					name: 'no',
					title: '#',
				},
				{
					name: 'customer',
					title: 'Customer',
					// sortField: 'customer',
				},
				{
					name: 'jobfile_no',
					title: 'Jobfile No',
					// sortField: 'jobfile_no',
				},
				{
					name: 'si_number',
					title: 'SI Number',
					// sortField: 'si_number',
				},
				{
					name: 'marketing',
					title: 'Marketing',
					// sortField: 'marketing',
				},
				{
					name: 'jobfile_type',
					title: 'Jobfile Type',
					// sortField: 'jobfile_type',
				},
				{
					name: 'date_transaksi',
					title: 'Date Transaksi',
					// sortField: 'date_transaksi',
				},
				{
					name: 'term',
					title: 'Term',
					// sortField: 'term',
				},
				{
					name: 'pay_date',
					title: 'Pay Ontime / Delay',
					// sortField: 'pay_date',
				},
				{
					name: 'status',
					title: 'Status',
					titleClass: 'text-center',
					dataClass: 'text-center ',
				},
			],
			css: {
				pagination: {
					activeClass: 'btn-success text-white',
					pageClass: 'btn btn-sm',
					linkClass: 'btn btn-sm',
					icons: {
						prev: 'fas fa-arrow-left',
						next: 'fas fa-arrow-right',
					},
				},
			},
			momentFormatStartDateData: {
				// this.dataPayload.year
				stringify: (date) => {
					return date ? moment(this.startDateData).format('YYYY/MM/DD') : ''
				},
			},
			momentFormatEndDateData: {
				// this.dataPayload.year
				stringify: (date) => {
					return date ? moment(this.endDateData).format('YYYY/MM/DD') : ''
				},
			},
			year: null,
			createdBy: null,
			statusData: 'All',
			startDateData: null,
			endDateData: null,
			branchData: [],
			marketingData: [],
			rangeData: 'customData',
			topCustomerData: '15',
			compareData: false,
			isManualTopCustData: false,
			isAutoTopCustData: true,
			monthData: '01',

			yearData: null,
			formatYearData: {
				// this.dataPayload.year
				stringify: (date) => {
					return date ? moment(this.yearData).format('YYYY') : ''
				},
			},
			isLoading: false,
			monthNames: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December',
			],
			jobfileTypeData: 'All',
			customerData: [],
		}
	},
	computed: {
		itemsWithSno() {
			return this.desserts.map((d, index) => ({ ...d, sno: index + 1 }))
		},
		...mapState('user', [
			'listUser',
			'dataUser',
			'listMarketingByBranchData1',
			'listMarketingByPermissions',
			'listMarketingByBranchData2',
		]),
		...mapState('userBranch', ['listUserBranch']),
		...mapState('customer', ['listCustomerByBranch']),
	},
	mounted() {
		this.getUser()
		// this.getMarketingByBranchData()
		this.getMarketingByPermission()
		this.getCustomerByBranch()
		// this.getMarketingByBranchData2();
		this.get_branch()
	},
	methods: {
		...authMethods,
		errorAlertValidation: function (message = 'Terjadi Kesalahan') {
			this.$swal({
				title: message,
				type: 'error',
				timer: '2000',
				toast: true,
				position: 'bottom-end',
			})
		},
		loginByToken() {
			console.log('login by token')
		},
		...mapActions('user', [
			'getListUser',
			'getListMarketingByBranchData1',
			'getListMarketingByBranchData2',
			'getListMarketingByPermissions',
		]),
		...mapActions('userBranch', ['getListUserBranch']),
		...mapActions('quotation', ['deleteQuotation', 'generatePdf']),
		...mapActions('customer', ['getListCustomerByBranch']),
		doFilter() {
			this.onFilterSet(this.filterText)
		},
		doFilterMultipleKeywords() {
			this.onFilterSetMultipleKeywords()
		},
		/**
		 * Table Length Change
		 *
		 * @return  {[type]}  [return description]
		 */
		onChangeTableLength() {
			// this.$refs.vuetable.reload()
			this.$nextTick(() => this.$refs.vuetable.refresh())
		},
		/**
		 * Pagination page change
		 */
		onChangePage(page) {
			this.$refs.vuetable.changePage(page)
		},
		/**
		 * Pagination info and pagination show
		 */
		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData)
			this.$refs.paginationInfo.setPaginationData(paginationData)
		},
		/**
		 * [onFilterSet description]
		 *
		 * @param   {[type]}  filterText  [filterText description]
		 *
		 * @return  {[type]}              [return description]
		 */
		onFilterSet(filterText) {
			this.moreParams = {
				search: filterText,
			}
			this.$nextTick(() => this.$refs.vuetable.refresh())
		},
		onFilterSetMultipleKeywords(filterText) {
			let tempStartDateData = ''
			let tempEndDateData = ''

			if (this.rangeData === 'customData') {
				// tempStartDateData = this.startDateData
				// tempEndDateData = this.endDateData

				tempStartDateData = new Date(this.startDateData).getFullYear() + '/' + (new Date(this.startDateData).getMonth() + 1) + '/' + new Date(this.startDateData).getDate()
				tempEndDateData = new Date(this.endDateData).getFullYear() + '/' + (new Date(this.endDateData).getMonth() + 1) + '/' + new Date(this.endDateData).getDate()
			} else if (this.rangeData === 'monthlyData') {
				if (this.yearData === null) {
					tempStartDateData = null
					tempEndDateData = null
				} else {
					tempStartDateData = new Date(
						new Date(this.yearData.toString()).getFullYear(),
						parseInt(this.monthData.toString()) - 1,
						2
					)
						.toISOString()
						.split('T')[0]
					tempEndDateData = new Date(
						new Date(this.yearData.toString()).getFullYear(),
						parseInt(this.monthData.toString()),
						1
					)
						.toISOString()
						.split('T')[0]

					// tempStartDateData = new Date(
					//   new Date(this.yearData.toString()).getFullYear(),
					//   new Date(this.monthData.toString()).getMonth(),
					//   2
					// )
					//   .toISOString()
					//   .split('T')[0]
					// tempEndDateData = new Date(
					//   new Date(this.yearData.toString()).getFullYear(),
					//   new Date(this.monthData.toString()).getMonth() + 1,
					//   1
					// )
					//   .toISOString()
					//   .split('T')[0]
				}
			} else if (this.rangeData === 'yearlyData') {
				if (this.yearData === null) {
					tempStartDateData = null
					tempEndDateData = null
				} else {
					tempStartDateData = new Date(
						new Date(this.yearData.toString()).getFullYear() + '/01/01'
					)
					tempEndDateData = new Date(
						new Date(this.yearData.toString()).getFullYear() +
						'/12/' +
						new Date(
							new Date(this.yearData.toString()).getFullYear(),
							12,
							0
						).getDate()
					)
				}
			}

			let strMarketing = []
			this.marketingData.forEach((data, idx) => {
				strMarketing.push(data.id_user)
			})

			let strCustomer = []
			this.customerData.forEach((data, idx) => {
				strCustomer.push(data.id)
			})

			this.moreParams = {
				branch: localStorage.getItem('default_branch'),
				customer: strCustomer,
				jobfileType: this.jobfileTypeData,
				startDate: tempStartDateData,
				endDate: tempEndDateData,
				sales: strMarketing,
				status: this.statusData,
				perPage: 10,
				filter: true,
			}
			// console.log(this.moreParams, 'THIS PARAMSSS')
			// console.log(filterText)

			if (this.jobfileType === '') {
				this.errorAlertValidation('Jobfile Type is required!')
			} else if (this.statusData === '') {
				this.errorAlertValidation('Status is required!')
			} else if (this.rangeData === '') {
				this.errorAlertValidation('Range is required!')
			} else if (this.rangeData !== '') {
				if (this.rangeData === 'customData') {
					if (this.startDateData === '' || this.startDateData == null) {
						this.errorAlertValidation('Start Date is required!')
					} else if (this.endDateData2 === '' || this.endDateData == null) {
						this.errorAlertValidation('End Date is required!')
					} else {
						if (this.diffDays(new Date(this.startDateData), new Date(this.endDateData)) > 30) {
							this.errorAlertValidation('The data displayed / exported must not be more than 30 days')
						} else {
							this.$nextTick(() => this.$refs.vuetable.refresh())
						}
					}
				} else if (this.rangeData === 'monthlyData') {
					if (this.monthData === '' || this.monthData == null) {
						this.errorAlertValidation('Month is required!')
					} else if (this.yearData === '' || this.yearData == null) {
						this.errorAlertValidation('Year is required!')
					} else {
						this.$nextTick(() => this.$refs.vuetable.refresh())
					}
				} else if (this.rangeData === 'yearlyData') {
					if (this.yearData === '' || this.yearData == null) {
						this.errorAlertValidation('Year is required!')
					} else {
						this.$nextTick(() => this.$refs.vuetable.refresh())
					}
				}
			}
			console.log(this.rangeData);
			// this.$nextTick(() => this.$refs.vuetable.refresh())
		},
		/**
		 * Show Table Loading
		 *
		 * @return  {[type]}  [return description]
		 */
		loadingTable() {
			this.isLoadingTable = true
		},

		/**
		 * Hide Loading if Success
		 *
		 * @return  {[type]}  [return description]
		 */
		loadTableSuccess() {
			this.isLoadingTable = false
		},
		/**
		 * Hide Loading if Failed
		 *
		 * @return  {[type]}  [return description]
		 */
		loadTableFailed(error) {
			console.log(error)

			this.isLoadingTable = false
			this.$swal({
				title: 'Terjadi Kesalahan',
				type: 'error',
				timer: '2000',
				toast: true,
				position: 'bottom-end',
			})
			// }
		},
		getUser() {
			try {
				this.getListUser()
					.then((res) => {
						console.log(res, 'res user')
						// this.user_id = res.result[1].id
					})
					.catch((err) => {
						console.log(err)
					})
			} catch (error) {
				console.log(error)
			}
		},
		getMarketingByBranchData(permission) {
			try {
				// let request = []
				// let strBranch = 'id_branch[]=' + localStorage.getItem('default_branch')
				// this.branchData.forEach((data, idx) => {
				//   if (idx === 0) {
				//     strBranch += 'id_branch[]=' + data.id_branch
				//     request.push(strBranch, permission)
				//   } else {
				//     strBranch += '&id_branch[]=' + data.id_branch
				//     request.push(strBranch, permission)
				//   }
				// })
				let permissionName = '&permissionName[] =' + permission
				console.log(permissionName, 'permissionName');
				this.getListMarketingByPermissions(permissionName)
					.then((res) => {
						// console.log(res, 'test return permission')
						// this.user_id = res.result[1].id
					})
				// .catch((err) => {
				//   console.log(err)
				// })
			} catch (error) {
				console.log(error)
			}
		},
		getMarketingByPermission() {
			for (
				let i = 0;
				i < localStorage.getItem('userPermissions').length;
				i++
			) {
				let enable = 'REPORT_ONTIME_ENABLE'
				let view = 'REPORT_ONTIME_VIEW'
				let user = 'REPORT_ONTIME_USERS'
				let parseUserPermission = localStorage.getItem('userPermissions')
				if (
					parseUserPermission.includes(enable) && parseUserPermission.includes(view) && parseUserPermission.includes(user)
				) {
					this.getMarketingByBranchData(view);
					break
				}
				else if (parseUserPermission.includes(enable) && parseUserPermission.includes(view)) {
					this.getMarketingByBranchData(view);
					break
				}
				else if (parseUserPermission.includes(user)) {
					this.getMarketingByBranchData(user);
					break
				}
				else if (parseUserPermission.includes(enable)) {
					this.getMarketingByBranchData(enable);
					break
				}
			}
		},
		getCustomerByBranch() {
			try {
				this.getListCustomerByBranch()
					.then((res) => { })
					.catch((err) => {
						console.log(err)
					})
			} catch (error) { }
		},
		formatDate(type) {
			let tempStartDateData = ''
			let tempEndDateData = ''

			// console.log(this.monthData1.toString())
			// console.log(new Date(this.yearData2.toString()).getFullYear())

			if (this.rangeData === 'customData') {
				tempStartDateData = this.startDateData
				tempEndDateData = this.endDateData
			} else if (this.rangeData === 'monthlyData') {
				if (this.yearData === null) {
					tempStartDateData = null
					tempEndDateData = null
				} else {
					tempStartDateData = new Date(
						new Date(this.yearData.toString()).getFullYear(),
						new Date(this.monthData.toString()).getMonth(),
						2
					)
						.toISOString()
						.split('T')[0]
					tempEndDateData = new Date(
						new Date(this.yearData.toString()).getFullYear(),
						new Date(this.monthData.toString()).getMonth() + 1,
						1
					)
						.toISOString()
						.split('T')[0]
				}
			} else if (this.rangeData === 'yearlyData') {
				if (this.yearData === null) {
					tempStartDateData = null
					tempEndDateData = null
				} else {
					tempStartDateData = new Date(
						new Date(this.yearData.toString()).getFullYear() + '/01/01'
					)
					tempEndDateData = new Date(
						new Date(this.yearData.toString()).getFullYear() +
						'/12/' +
						new Date(
							new Date(this.yearData.toString()).getFullYear(),
							12,
							0
						).getDate()
					)
				}
			}

			if (tempStartDateData === null || tempEndDateData === null) {
				return ''
			}
			return (
				this.monthNames[tempStartDateData.getMonth()] +
				' ' +
				tempStartDateData.getFullYear() +
				' - ' +
				this.monthNames[tempEndDateData.getMonth()] +
				' ' +
				tempEndDateData.getFullYear()
			)
		},
		get_branch() {
			this.getListUserBranch()
				.then((res) => {
					// location.reload()
					let defaultBranch = parseInt(localStorage.getItem('default_branch'))
					console.log(this.listUserBranch)
					if (res !== null && res !== undefined) {
						res.forEach((dt) => {
							if (dt.branch.id === defaultBranch) {
								this.branchData = dt.branch.nama_branch
							}
						})
					}
					console.log(res, 'THIS RESPONSE BRANCH')
				})
				.catch((err) => {
					console.log(err)
					this.errorAlert()
				})
		},
		getMarketingData() {
			this.getMarketingByPermissions()
		},
		getMarketingData2() {
			// this.getMarketingByBranchData2();
		},
		fecthData() {
			console.log('OK')
		},
		changeTypeTopCustData() {
			this.isManualTopCustData = !this.isManualTopCustData
			this.isAutoTopCustData = !this.isAutoTopCustData
			if (this.isAutoTopCustData) {
				this.topCustomerData = 15
			}
		},
		exportData(filterText) {
			let tempStartDateData = ''
			let tempEndDateData = ''

			if (this.rangeData === 'customData') {
				tempStartDateData = new Date(
					new Date(this.startDateData.toString()).getFullYear(),
					new Date(this.startDateData.toString()).getMonth(),
					new Date(this.startDateData.toString()).getDate() + 1
				)
					.toISOString()
					.split('T')[0]

				tempEndDateData = new Date(
					new Date(this.endDateData.toString()).getFullYear(),
					new Date(this.endDateData.toString()).getMonth(),
					new Date(this.endDateData.toString()).getDate() + 1
				)
					.toISOString()
					.split('T')[0]
			} else if (this.rangeData === 'monthlyData') {
				if (this.yearData === null) {
					tempStartDateData = null
					tempEndDateData = null
				} else {
					tempStartDateData = new Date(
						new Date(this.yearData.toString()).getFullYear(),
						parseInt(this.monthData.toString()) - 1,
						2
					)
						.toISOString()
						.split('T')[0]
					tempEndDateData = new Date(
						new Date(this.yearData.toString()).getFullYear(),
						parseInt(this.monthData.toString()),
						1
					)
						.toISOString()
						.split('T')[0]
				}
			} else if (this.rangeData === 'yearlyData') {
				if (this.yearData === null) {
					tempStartDateData = null
					tempEndDateData = null
				} else {
					tempStartDateData = new Date(
						new Date(this.yearData.toString()).getFullYear(),
						0,
						2
					)
						.toISOString()
						.split('T')[0]
					tempEndDateData = new Date(
						new Date(this.yearData.toString()).getFullYear(),
						12
					)
						.toISOString()
						.split('T')[0]
				}
			}

			let strMarketing = []
			this.marketingData.forEach((data, idx) => {
				if (idx === 0) {
					strMarketing += 'sales[]=' + data.id_user
				} else {
					strMarketing += '&sales[]=' + data.id_user
				}
			})

			let strCustomer = []
			this.customerData.forEach((data, idx) => {
				if (idx === 0) {
					strCustomer += 'customer[]=' + data.id
				} else {
					strCustomer += '&customer[]=' + data.id
				}
			})

			var idCompany;
			var branch;

			if (this.jobfileType === '') {
				this.errorAlertValidation('Jobfile Type is required!')
			} else if (this.statusData === '') {
				this.errorAlertValidation('Status is required!')
			} else if (this.rangeData === '') {
				this.errorAlertValidation('Range is required!')
			} else if (this.rangeData !== '') {
				if (this.rangeData === 'customData') {
					if (this.startDateData === '' || this.startDateData == null) {
						this.errorAlertValidation('Start Date is required!')
					} else if (this.endDateData2 === '' || this.endDateData == null) {
						this.errorAlertValidation('End Date is required!')
					} else {
						idCompany = localStorage.getItem('id_company')
						branch = localStorage.getItem('default_branch')

						if (this.diffDays(new Date(this.startDateData), new Date(this.endDateData)) > 30) {
							this.errorAlertValidation('The data displayed / exported must not be more than 30 days')
						} else {
							// this.$nextTick(() => this.$refs.vuetable.refresh())
							window.location.href = `${this.$store.state.rootApi}/export-excel-ontime-delay?branch=${branch}&jobfileType=${this.jobfileTypeData}&startDate=${tempStartDateData}&endDate=${tempEndDateData}&status=${this.statusData}&${strMarketing}&company=${idCompany}&${strCustomer}&filter=true`
						}

					}
				} else if (this.rangeData === 'monthlyData') {
					if (this.monthData === '' || this.monthData == null) {
						this.errorAlertValidation('Month is required!')
					} else if (this.yearData === '' || this.yearData == null) {
						this.errorAlertValidation('Year is required!')
					} else {
						idCompany = localStorage.getItem('id_company')
						branch = localStorage.getItem('default_branch')

						window.location.href = `${this.$store.state.rootApi}/export-excel-ontime-delay?branch=${branch}&jobfileType=${this.jobfileTypeData}&startDate=${tempStartDateData}&endDate=${tempEndDateData}&status=${this.statusData}&${strMarketing}&company=${idCompany}&${strCustomer}&filter=true`
					}
				} else if (this.rangeData === 'yearlyData') {
					if (this.yearData === '' || this.yearData == null) {
						this.errorAlertValidation('Year is required!')
					} else {
						idCompany = localStorage.getItem('id_company')
						branch = localStorage.getItem('default_branch')

						window.location.href = `${this.$store.state.rootApi}/export-excel-ontime-delay?branch=${branch}&jobfileType=${this.jobfileTypeData}&startDate=${tempStartDateData}&endDate=${tempEndDateData}&status=${this.statusData}&${strMarketing}&company=${idCompany}&${strCustomer}&filter=true`
					}
				}
			}
		},
		diffDays(date, otherDate) {
			return (Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24)) + 1)
		}
	},
}
</script>
